import { Slugify } from "../Helpers/index";
class SearchSuggest {

    constructor() {
        let resultsLength = 0;
        let latestKeyword = "";

        let products = new Bloodhound({
            //hint: false,
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('Heading'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: '/Search/SearchSuggest/%QUERY',
                wildcard: '%QUERY',
                prepare: function (query, settings) { //check if it has any html encoded string
                    let htmlString = encodeURIComponent(query);
                    if (htmlString.indexOf("%") > -1) {
                        settings.url = '/Search/SearchSuggest?keyword=' + htmlString;
                        return settings;
                    } else {
                        settings.url = '/Search/SearchSuggest/' + query;
                        return settings;
                    }
                },
                transform: function (data) {
                    $("#null-results").show();                    

                    if (!data.searchSuggestModelList)
                    {
                        if (data)
                        return;
                    }

                    let keyword = $('#product-query').val();

                    let array = $.map(data.searchSuggestModelList, function (item, i) {

                        if (item.ItemGroupRecNo > 0) {
                            item.productURL = typeof item.Heading != 'undefined' ?
                                item.Heading !== null ?
                                    "/products/details/" + item.ItemGroupRecNo + "/" + Slugify(item.Heading) + "/" :
                                    "/products/details/" + item.ItemGroupRecNo + "/" : null;
                            item.keyword = keyword;
                        }

                        if (item.ItemGroupRecNo === -1) {
                            item.keyword = keyword;
                            item.productURL = "/ItemList/ContractPricingList?ItemID=" + item.ItemID;
                        }
                        return item;
                    });

                    resultsLength = array.length;
                    latestKeyword = keyword.toString();
                    return array;
                }
            }
        });

        let typeaheadOptions = {
            hint: true,
            highlight: true,
            minLength: 3
        },
            typeaheadDt = {
                name: 'Products',
                displayKey: 'keyword',
                source: products,
                limit: 5, // Must be at least 8 or else some keywords don't show up
                templates: {
                    //notFound: function () {
                    //    return '<div class="font-weight-bold px-2">No product records match that query</div>';
                    //},
                    suggestion: function (data) {
                        function MoneyCommas(nStr) {
                            nStr = nStr.toFixed(2);
                            nStr += '';
                            let x = nStr.split('.');
                            let x1 = x[0];
                            let x2 = x.length > 1 ? '.' + x[1] : '';
                            let rgx = /(\d+)(\d{3})/;
                            while (rgx.test(x1)) {
                                x1 = x1.replace(rgx, '$1' + ',' + '$2');
                            }
                            return x1 + x2;
                        }
                        console.log("data=================", data)
                        if (!data)
                            return;

                        let displayImage = data.ImageName !== null ?
                            '<div class="col-2 p-0 m-0 pr-2 my-1"><img class="img tw-mx-auto" src="/Content/ProductImages/80x60/' + data.ImageName + '" / ></div>'
                            + '<div class="d-flex flex-column col-10 p-0 m-0">' : '<div class="d-flex flex-column col-12 p-0 m-0">';

                        return '<div class="d-flex flex-row my-auto p-0 m-0 px-1 border-bottom">'
                            + displayImage
                            + '<div class="text-truncate">' + data.Heading + '</div>'
                            + '<small class="small text-black-50"> from $' + (data.LowestPrice !== null ? MoneyCommas(data.LowestPrice) : "") + '</span>'
                            + '</div >'
                            + '</div>';
                    },
                  footer: function () {
                      let hasSlash = latestKeyword.indexOf("/") > -1
                      let endpoint = hasSlash ? "/Search?keyword=" : "/Search/";

                      if (resultsLength <= 5)
                          return '<div class="search-suggest-footer"><a class="text-primary" href="' + endpoint + encodeURIComponent(latestKeyword.trim()) + (hasSlash ? "&rowSize=" + getRowSize() : '/' + getRowSize()) + '">'
                              + '<p class="text-center">Click here to view all item matches.</p>'
                              + '</a></div>';
                      else {
                          return '<div class="search-suggest-footer"><a class="text-primary" href="' + endpoint + encodeURIComponent(latestKeyword.trim()) + (hasSlash ? "&rowSize=" + getRowSize() : '/' + getRowSize()) + '">'
                              + '<p class="text-center">' + resultsLength + ' search results for "' + latestKeyword.trim() + '"</p>'
                              + '<p class="text-center">Click here to view all item matches.</p>'
                              + '</a></div>';
                      }
                    }
                }
            };

        $(() => {
            
            $('#product-search #product-query').typeahead(typeaheadOptions, typeaheadDt);

            $('#product-query').on('typeahead:selected', function (event, selectedItem, dataset) {
                event.preventDefault();
                //Store search term before redirect, retrieve later to highlight
                localStorage.setItem('LastSearchTerm', $(this).val().toString());
                window.location.href = selectedItem.productURL;
            });
            $('#search-icon-button').click(function () {
                let keyword = $('#product-query').val().toString().trim();
                if (keyword !== "") {
                    localStorage.setItem('LastSearchTerm', keyword);
                    return window.location.href = "/Search?keyword=" + encodeURIComponent(keyword) + '&rowSize=' + getRowSize();
                }
                else {
                    return;
                }
            })
            $('#product-query').on('keyup', this, function (event) {

                let keyword = $(this).val().toString();
                let encoded = encodeURIComponent(keyword.trim());

                let hasSlash = encoded.indexOf("%") > -1;
                let endpoint = hasSlash ? "/Search?keyword=" : "/Search/";

                if (event.key === "Enter") {
                    //Store search term before redirect, retrieve later to highlight
                    localStorage.setItem('LastSearchTerm', keyword);
                    $(this).typeahead('close');

                    if (encoded == '') {
                        window.location.href = endpoint;
                    }
                    else {
                        window.location.href = endpoint + encoded + (hasSlash ? "&rowSize=" + getRowSize() : '/' + getRowSize());
                    }
                };
            });
        });
    }
}

new Promise<void>((resolveTypeahead) => {
    new SearchSuggest();
    resolveTypeahead();

}).then((resolveTypeahead) => {
    if (resolveTypeahead !== null) {
        console.info("Search suggest typeahead loaded");
    }
}).catch((error) => {
    console.error(error);
    console.log("Error in typeahead promise chain, logfile dump: ", window.LogFile);
});

function getRowSize() {
  if (!window.screen.width) return 4;
  if (window.screen.width >= 992) return 4;
  if (window.screen.width >= 768 && window.screen.width < 992) return 3;
  if (window.screen.width < 768) return 2;
}